import React, {useEffect} from 'react';
import Footer from '../Templates/Shared/Footer';
import Header from '../Templates/Shared/Header';
import Helmet from 'react-helmet';
import htmr from 'htmr';
import termsPageData from '../../data/staticPages/termsPage.json';
import styles from '../Templates/Styles/staticpage.module.scss';
import SEO from '../Templates/SEO';
import {getCanonicalURLs, migratedPartners, handleScroll} from
  '../Templates/Shared/StaticPageService';
import {getParameterByName} from '../Templates/Shared/Utils';
import cookie from 'react-cookies';

const Terms = () => {
  const locale = 'en';
  const content = termsPageData.sections[0].pagesection.content;
  const canonicals = getCanonicalURLs(termsPageData.seo.canonicalurls);

  useEffect(() => {
    const utmSource = cookie.load(
        'utm_source') || getParameterByName('utm_source');
    const isMigratedPartner = migratedPartners.includes(utmSource);
    const id = document.getElementById('signupcta');
    if (isMigratedPartner) {
      if (id) {
        id.href = `https://learning.upskillist.com/${utmSource}`;
      }
    } else {
      if (id) {
        if (utmSource?.toLowerCase()?.startsWith('sc') ||
          utmSource?.toLowerCase()?.startsWith('stack')) {
          id.href = `https://learning.upskillist.com/stackcommerce`;
        } else {
          id.href = `https://learning.upskillist.com/?utm_source=${utmSource}&utm_medium=partners`;
        }
      }
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <SEO
        locale={locale}
        title={termsPageData.seo.title}
        description={termsPageData.seo.description}
        image={''}
        canonicalUrls={canonicals}
        languageCanonicals={termsPageData.languageCanonicals}
        schemaMarkup={null}
      />
      <Helmet>
        <script>
          window.dataLayer=window.dataLayer||[];
        </script>
      </Helmet>
      <Header locale={locale} />
      <main className={`terms container ${styles.staticPage}`}>
        <div>
          {htmr(content)}
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Terms;
